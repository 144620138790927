import { CheckCircleOutlined } from '@ant-design/icons';
import PATH from '@models/constants/Path';
import { OrganisationPermission } from '@models/types/Organisation';
import Route from '@models/types/Routes';
import { lazy } from 'react';

const LazyTrustManagementListPage = lazy(
  () =>
    import(
      '@pages/dashboard/trustManagement/TrustManagementListPage/TrustManagementListPage.adapter'
    ),
);

const LazyTrustManagementDetailsPage = lazy(
  () =>
    import(
      '@pages/dashboard/trustManagement/TrustManagementDetailsPage/TrustManagementDetailsPage.adapter'
    ),
);

const LazyTrustManagementCreatePage = lazy(
  () =>
    import(
      '@pages/dashboard/trustManagement/TrustManagementCreatePage/TrustManagementCreatePage.adapter'
    ),
);

const LazyTrustManagementEditPage = lazy(
  () =>
    import(
      '@pages/dashboard/trustManagement/TrustManagementEditPage/TrustManagementEditPage.adapter'
    ),
);

const TrustManagementRoute: Route = {
  children: [
    {
      component: LazyTrustManagementDetailsPage,
      id: 'trust-entity-details',
      path: PATH.TrustManagement.Details(':trustEntityId'),
      requiredPermission: OrganisationPermission.TrustEntityDetails,
    },
    {
      component: LazyTrustManagementCreatePage,
      id: 'trust-entity-create',
      path: PATH.TrustManagement.Create,
      requiredPermission: OrganisationPermission.TrustEntityCreate,
    },
    {
      component: LazyTrustManagementEditPage,
      id: 'trust-entity-edit',
      path: PATH.TrustManagement.Edit(':trustEntityId'),
      requiredPermission: OrganisationPermission.TrustEntityEdit,
    },
  ],
  component: LazyTrustManagementListPage,
  hideInSidebar: false,
  iconComponent: CheckCircleOutlined,
  id: 'trust-management',
  path: PATH.TrustManagement.List(),
  requiredPermission: OrganisationPermission.TrustAnchorList,
  serverSupportVerifier: (serverConfig) =>
    Boolean(
      serverConfig?.trustManagement &&
        Object.keys(serverConfig.trustManagement).length >= 1,
    ),
  title: 'route.trustManagement.title',
  withTabs: true,
};

export default TrustManagementRoute;

import { API_CONFIG_STORAGE_KEY } from '@models/constants/Storage';
import {
  ApiConfigStore,
  ApiConfigStoreModel,
  ApiConfigStoreSnapshot,
  ApiConfigStoreSnapshotIn,
} from '@models/store/apiConfig/ApiConfigStore';
import { getPersistedData, persistData } from '@store/Storage';
import { onSnapshot } from 'mobx-state-tree';

export const setupApiConfigStore = () => {
  let apiConfigStore: ApiConfigStore;

  const initData = {
    allPermissionsEnabled: false,
    credentialsSchemaDetailsMockEnabled: false,
    credentialsSchemaListMockEnabled: false,
    didsDeactivatedMockEnabled: false,
    didsDetailsMockEnabled: false,
    didsListMockEnabled: false,
    integrationDetailsMockEnabled: false,
    integrationListMockEnabled: false,
    keysDetailsMockEnabled: false,
    keysListMockEnabled: false,
    proofRequestListMockEnabled: false,
    serverConfigMockEnabled: false,
    trustAnchorDetailsMockEnabled: false,
    trustAnchorListMockEnabled: false,
    trustEntityDetailsMockEnabled: false,
    trustEntityListMockEnabled: false,
  };

  try {
    const storedAuthData = getPersistedData<Partial<ApiConfigStoreSnapshotIn>>(
      API_CONFIG_STORAGE_KEY,
    );
    const mergedData = { ...initData, ...storedAuthData };

    apiConfigStore = ApiConfigStoreModel.create(mergedData);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error("Couldn't load persistent api config store state", e);
    apiConfigStore = ApiConfigStoreModel.create(initData);
  }

  onSnapshot(apiConfigStore, (snapshot) => {
    const persistableData: Partial<ApiConfigStoreSnapshot> = {
      allPermissionsEnabled: snapshot.allPermissionsEnabled,
      credentialsSchemaDetailsMockEnabled:
        snapshot.credentialsSchemaDetailsMockEnabled,
      credentialsSchemaListMockEnabled:
        snapshot.credentialsSchemaListMockEnabled,
      didsDeactivateMockEnabled: snapshot.didsDeactivateMockEnabled,
      didsDetailsMockEnabled: snapshot.didsDetailsMockEnabled,
      didsListMockEnabled: snapshot.didsListMockEnabled,
      integrationDetailsMockEnabled: snapshot.integrationDetailsMockEnabled,
      integrationListMockEnabled: snapshot.integrationListMockEnabled,
      keysDetailsMockEnabled: snapshot.keysDetailsMockEnabled,
      keysListMockEnabled: snapshot.keysListMockEnabled,
      proofRequestListMockEnabled: snapshot.proofRequestListMockEnabled,
      serverConfigMockEnabled: snapshot.serverConfigMockEnabled,
      trustAnchorDetailsMockEnabled: snapshot.trustAnchorDetailsMockEnabled,
      trustAnchorListMockEnabled: snapshot.trustAnchorListMockEnabled,
      trustEntityDetailsMockEnabled: snapshot.trustEntityDetailsMockEnabled,
      trustEntityListMockEnabled: snapshot.trustEntityListMockEnabled,
    };

    persistData<Partial<ApiConfigStoreSnapshot>>(
      persistableData,
      API_CONFIG_STORAGE_KEY,
    );
  });

  return apiConfigStore;
};
